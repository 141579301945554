import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Booking.css';
import Header from '../components/Header';
import axios from 'axios';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import PaymentHistory from './PaymentHistory';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const BookingPage = () => {
    const [bookings, setBookings] = useState([]);
    const [filteredBookings, setFilteredBookings] = useState([]);
    const [selectedDate, setSelectedDate] = useState('');
    const [selectedSlotTime, setSelectedSlotTime] = useState('all');
    const [chartData, setChartData] = useState(null);
    const [viewMode, setViewMode] = useState('sales');
    const [summary, setSummary] = useState({ totalBookings: 0, totalRevenue: 0, amountTobePaid: '' });
    const [amountPaid, setAmountPaid] = useState(0); // Total amount paid till date

    const getAllBooking = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/booking`, {
                headers: {
                    auth: document.cookie.replace(/(?:(?:^|.*;\s*)auth\s*=\s*([^;]*).*$)|^.*$/, "$1"),
                },
            });
            const bookings = response.data.Booking;
            setBookings(bookings);
            setFilteredBookings(bookings);
            generateChartData(bookings);
            calculateSummary(bookings);
        } catch (error) {
            console.error('Error fetching bookings:', error);
        }
    };

    const getPayments = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/banking/get_payment`, {
                headers: {
                    auth: document.cookie.replace(/(?:(?:^|.*;\s*)auth\s*=\s*([^;]*).*$)|^.*$/, "$1"),
                },
            });
            const payments = response.data.payments;

            // Calculate total amount paid
            const totalPaid = payments.reduce((sum, payment) => sum + payment.amount_paid, 0);
            setAmountPaid(totalPaid);
        } catch (error) {
            console.error('Error fetching payments:', error);
        }
    };

    useEffect(() => {
        getAllBooking();
        getPayments();
    }, []);

    useEffect(() => {
        filterBookings();
    }, [selectedDate, selectedSlotTime, bookings]);

    const filterBookings = () => {
        let filtered = bookings;

        if (selectedDate) {
            filtered = filtered.filter((booking) => booking.bookingDate === selectedDate);
        }

        if (selectedSlotTime !== 'all') {
            filtered = filtered.filter((booking) => booking.slotStartTime === selectedSlotTime);
        }

        setFilteredBookings(filtered);
    };

    const calculateSummary = (bookings) => {
        const totalRevenue = bookings.reduce((sum, booking) => {
            return booking.isPaid ? sum + booking.subscriptionPrice : sum;
        }, 0);

        const totalBookings = bookings.length;

        const amountTobePaid = totalRevenue - totalRevenue * 15 / 100;

        setSummary({ totalBookings, totalRevenue, amountTobePaid });
    };

    const generateChartData = (bookings) => {
        const bookingsByDate = bookings.reduce((acc, booking) => {
            const date = new Date(booking.bookingDate).toLocaleDateString();
            acc[date] = acc[date] || { sales: 0, count: 0 };
            acc[date].sales += booking.subscriptionPrice;
            acc[date].count += 1;
            return acc;
        }, {});

        const dates = Object.keys(bookingsByDate);
        const data = dates.map((date) => bookingsByDate[date][viewMode]);

        setChartData({
            labels: dates,
            datasets: [
                {
                    label: viewMode === 'sales' ? 'Sales (INR)' : 'Bookings Count',
                    data,
                    borderColor: 'rgba(75, 192, 192, 1)',
                    backgroundColor: 'rgba(75, 192, 192, 0.2)',
                    fill: true,
                },
            ],
        });
    };



    const refreshData = () => {
        getAllBooking();
        getPayments();
    };

    return (
        <>
            <Header />
            <div className="container-fluid gym-display-container">
                <div className="card shadow-lg mb-4">
                    <div className="card-header bg-info text-white d-flex justify-content-between align-items-center">
                        <h2 className="text-center mb-0">Booking Management</h2>
                        <button className="btn btn-light btn-sm" onClick={refreshData}>
                            Refresh
                        </button>
                    </div>
                    <div className="card-body">
                        {/* Summary Widget */}
                        <div className="row text-center mb-4">
                            <div className="col-md-3 col-sm-6 mb-4">
                                <div className="stat-card bg-gradient-primary text-white">
                                    <div className="stat-icon">📅</div>
                                    <h5 className="stat-title">Total Bookings</h5>
                                    <p className="stat-value">{Math.floor(summary.totalBookings)}</p>
                                </div>
                            </div>

                            <div className="col-md-3 col-sm-6 mb-4">
                                <div className="stat-card bg-gradient-success text-white">
                                    <div className="stat-icon">💰</div>
                                    <h5 className="stat-title">Total Revenue</h5>
                                    <p className="stat-value">INR {Math.floor(summary.totalRevenue)}</p>
                                </div>
                            </div>

                            <div className="col-md-3 col-sm-6 mb-4">
                                <div className="stat-card bg-gradient-warning text-dark">
                                    <div className="stat-icon">📤</div>
                                    <h5 className="stat-title">To Be Paid</h5>
                                    <p className="stat-value">INR {Math.floor(summary.amountTobePaid - amountPaid) || 'N/A'}</p>
                                </div>
                            </div>

                            <div className="col-md-3 col-sm-6 mb-4">
                                <div className="stat-card bg-gradient-info text-white">
                                    <div className="stat-icon">✅</div>
                                    <h5 className="stat-title">Amount Paid</h5>
                                    <p className="stat-value">INR {Math.floor(amountPaid) || 'N/A'}</p>
                                </div>
                            </div>
                        </div>

                        <PaymentHistory summary={summary} />


                    </div>
                </div>
            </div>
        </>
    );
};

export default BookingPage;
