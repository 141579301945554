import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Card, Button, Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faClipboardCheck, faTrophy, faChartLine } from '@fortawesome/free-solid-svg-icons';
import Header from '../components/Header';
import './Benefits.css';
import { useNavigate } from 'react-router-dom';

const GymBenefits = () => {
    const navigate = useNavigate();
    const benefits = [
        {
            icon: faClipboardCheck,
            title: 'Effortless Gym Management',
            text: 'Track all monthly bookings, payments, and visited customers in one place.',
        },
        {
            icon: faClock,
            title: 'Exciting Rewards',
            text: 'Accumulate 100 booking hours and unlock exclusive prizes and milestone rewards.',
        },
        {
            icon: faChartLine,
            title: 'Growth & Analytics',
            text: 'Get insights into your gym’s performance and customer engagement to maximize revenue.',
        },
        {
            icon: faTrophy,
            title: 'Achieve More Milestones',
            text: 'Unlock new levels of success with our gym management tools and reward system.',
        }
    ];

    return (
        <div className="gym-benefits">
            <Header />
            <Container>
                <h2 className="text-center mt-4 text-primary fw-bold">Why Choose Our Gym Management Software?</h2>
                <p className="text-center lead">Streamline operations, increase revenue, and achieve exciting milestones.</p>
                <Row className="mt-5">
                    {benefits.map((benefit, index) => (
                        <Col key={index} md={6} className="mb-4">
                            <Card className="benefit-card text-center shadow-lg border-0 p-3">
                                <Card.Body>
                                    <FontAwesomeIcon icon={benefit.icon} className="benefit-icon mb-3 text-success" size="3x" />
                                    <Card.Title className="fw-bold">{benefit.title}</Card.Title>
                                    <Card.Text className="text-muted">{benefit.text}</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
                <div className="text-center mt-5">
                    <Button variant="success" size="lg" className="px-5 py-3 fw-bold shadow" onClick={() => navigate("/register")}>Get Started Today</Button>
                </div>
            </Container>
        </div>
    );
};

export default GymBenefits;