import React from 'react';
import './YupluckBanner.css';
import PlayStoreIcon from '../img/playstore.png';
import AppStoreIcon from '../img/appstore.png';
import BackgroundImage from '../img/yupluck app.png';

const YupluckBanner = () => {
  return (
    <section
      className="hero-cricheroes-style"
    >
      <div className="overlay" />
      <div className="content-wrapper container">
        <h1>
          Bringing <span className="">gyms & people</span> together<br />in a smarter way.
        </h1>
        <p className="subtext">
          Join our growing fitness community and <span className="bold">book workouts effortlessly.</span>
        </p>

        <div className="store-buttons">
          <a
            href="https://play.google.com/store/apps/details?id=com.yupluck.mepluck"
            target="_blank"
            rel="noreferrer"
          >
            <img src={PlayStoreIcon} alt="Play Store" />
          </a>
          <a
            href="https://apps.apple.com/in/app/yupluck/id6737851845"
            target="_blank"
            rel="noreferrer"
          >
            <img src={AppStoreIcon} alt="App Store" />
          </a>
        </div>

        <div className="early-message">
          🚀 Be an early adopter and help shape the future of fitness!
        </div>
      </div>
    </section>
  );
};

export default YupluckBanner;
