import React from "react";
import "./GymOnboardingComponent.css";

import EquipmentImg from "../img/Equipment.PNG";
import SlotImg from "../img/Slot.png";
import BankDetailsImg from "../img/BankDetails.PNG";
import PaymentsImg from "../img/Payments.PNG";

export default function GymOnboardingComponent() {
  return (
    <div className="onboard-wrapper">
      <h1 className="onboard-title">
        Onboard Your Gym in Just a Few Steps!
      </h1>

      <div className="onboard-grid">
        {/* Equipment Section */}
        <div className="onboard-card">
          <img
            src={EquipmentImg}
            alt="Add Equipment"
            className="onboard-image"
          />
          <h2 className="onboard-step">Step 1: Add Equipment</h2>
          <p className="onboard-text">
            Easily list all the gym equipment you offer. Maintain and manage your inventory right from the dashboard.
          </p>
        </div>

        {/* Slot Section */}
        <div className="onboard-card">
          <img
            src={SlotImg}
            alt="Add Slot"
            className="onboard-image"
          />
          <h2 className="onboard-step">Step 2: Add Time Slots</h2>
          <p className="onboard-text">
            Set up your gym time slots with flexible capacity and pricing. Enable or disable them as needed.
          </p>
        </div>

        {/* Bank Details Section */}
        <div className="onboard-card">
          <img
            src={BankDetailsImg}
            alt="Add Bank Details"
            className="onboard-image"
          />
          <h2 className="onboard-step">Step 3: Add Bank Details</h2>
          <p className="onboard-text">
            Enter your account details so that you can receive payments directly to your bank.
          </p>
        </div>

        {/* Business Ready Section */}
        <div className="onboard-card">
          <img
            src={PaymentsImg}
            alt="Ready for Business"
            className="onboard-image"
          />
          <h2 className="onboard-ready">You're Ready for Business!</h2>
          <p className="onboard-text">
            Once setup is complete, start receiving bookings, track revenue, and manage your gym with ease.
          </p>
        </div>
      </div>
    </div>
  );
}
