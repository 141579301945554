import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Slots.css';
import Header from '../components/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faPlusCircle, faEdit, faTrash, faToggleOn, faToggleOff, faMinusCircle, faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

const SlotPage = () => {
    const [slotList, setSlotList] = useState([]);
    const [editingSlot, setEditingSlot] = useState(null);
    const [newPrice, setNewPrice] = useState('');
    const [newSlot, setNewSlot] = useState({
        startTime: '',
        capacity: '',
        price: '',
        timePeriod: '1',
    });
    const navigate = useNavigate();

    useEffect(() => {
        fetchSlots();
    }, []);

    const fetchSlots = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/slots`, {
                headers: { auth: document.cookie.replace(/(?:(?:^|.*;\s*)auth\s*=\s*([^;]*).*$)|^.*$/, "$1") },
            });

            const sortedSlots = response.data.sort((a, b) => {
                // Convert startTime to Date objects
                const timeA = new Date(`1970-01-01T${a.startTime}`);
                const timeB = new Date(`1970-01-01T${b.startTime}`);
                return timeA - timeB; // Sort in ascending order
            });

            setSlotList(sortedSlots);
        } catch (error) {
            console.error('Error fetching slots:', error);
        }
    };

    const handleAddSlot = async () => {
        try {
            await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/slots`, newSlot, {
                headers: { auth: document.cookie.replace(/(?:(?:^|.*;\s*)auth\s*=\s*([^;]*).*$)|^.*$/, "$1") },
            });
            setNewSlot({ startTime: '', capacity: '', price: '', timePeriod: '1' });
            fetchSlots();
        } catch (error) {
            console.error('Error adding slot:', error);
        }
    };

    const handleEditSlot = (slot) => {
        setEditingSlot(slot.id);
        setNewPrice(slot.price);
    };

    const handleSaveSlot = async (id) => {
        try {
            await axios.put(
                `${process.env.REACT_APP_API_BASE_URL}/api/slots/${id}`,
                { price: newPrice },
                { headers: { auth: document.cookie.replace(/(?:(?:^|.*;\s*)auth\s*=\s*([^;]*).*$)|^.*$/, "$1") } }
            );
            setEditingSlot(null);
            setNewPrice('');
            fetchSlots();
        } catch (error) {
            console.error('Error updating slot price:', error);
        }
    };



    const handleDeleteSlot = async (id) => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/api/slots/${id}`, {
                headers: { auth: document.cookie.replace(/(?:(?:^|.*;\s*)auth\s*=\s*([^;]*).*$)|^.*$/, "$1") },
            });
            fetchSlots();
        } catch (error) {
            console.error('Error deleting slot:', error);
        }
    };

    const handleCancelEdit = () => {
        setEditingSlot(null);
        setNewPrice('');
    };

    const enabledisableAllSlot = async (val) => {
        const disableAllSlotsRecursive = async (slotList) => {
            if (slotList.length === 0) {
                console.log('All slots have been disabled.');
                return;
            }

            const [currentSlot, ...remainingSlots] = slotList; // Destructure to get the first slot and the rest

            try {
                await axios.put(`${process.env.REACT_APP_API_BASE_URL}/api/slots/disable/${currentSlot.id}`, { enable: val }, {
                    headers: { auth: document.cookie.replace(/(?:(?:^|.*;\s*)auth\s*=\s*([^;]*).*$)|^.*$/, "$1") },
                });
                fetchSlots();
                toast.success(`Slot with ID ${currentSlot.startTime}  updated successfully.`);
            } catch (error) {
                toast.error(`Error disabling slot with ID ${currentSlot.startTime}:`, error);
            }

            // Call the function recursively with the remaining slots
            disableAllSlotsRecursive(remainingSlots);
        };

        // Example usage
        disableAllSlotsRecursive(slotList);
    }

    const handleNext = () => navigate('/gallery');
    const handleBack = () => navigate('/equipment');

    const hourlySlots = Array.from({ length: 24 }, (_, i) => `${i.toString().padStart(2, '0')}:00`);

    return (
        <div className="slot-container">
            <ToastContainer />
            <Header />
            <h1>
                <FontAwesomeIcon icon={faClock} /> Slot Management
            </h1>
            <div className="add-slot-form">
                <div className="add-slot-header">
                    <span>
                        <FontAwesomeIcon icon={faPlusCircle} /> Add New Slot
                    </span>
                    <div className="button-container">
                        <button className="back-button" onClick={handleBack}>
                            <FontAwesomeIcon icon={faArrowLeft} /> Back
                        </button>
                        <button className="next-button" onClick={handleNext}>
                            Next <FontAwesomeIcon icon={faArrowRight} />
                        </button>
                    </div>
                </div>
                <div className="form-fields">
                    <select value={newSlot.startTime} onChange={(e) => setNewSlot({ ...newSlot, startTime: e.target.value })}>
                        <option value="" disabled>Select Time</option>
                        {hourlySlots.map((hour) => (
                            <option key={hour} value={hour}>{hour}</option>
                        ))}
                    </select>
                    <input type="number" placeholder="Capacity" value={newSlot.capacity} onChange={(e) => setNewSlot({ ...newSlot, capacity: e.target.value })} />
                    <input type="number" placeholder="Price" value={newSlot.price} onChange={(e) => setNewSlot({ ...newSlot, price: e.target.value })} />
                </div>
                <button className="add-button" onClick={handleAddSlot}>
                    <FontAwesomeIcon icon={faPlusCircle} /> Add Slot
                </button>



            </div>

            <div className="slot-card">

                <div className="bulk-actions">
                    <button className="action-button disable-all-button" onClick={() => enabledisableAllSlot(0)}>
                        <FontAwesomeIcon icon={faToggleOff} /> Disable All
                    </button>
                    <button className="action-button enable-all-button" onClick={() => enabledisableAllSlot(1)}>
                        <FontAwesomeIcon icon={faToggleOn} /> Enable All
                    </button>
                </div>

                {slotList.length === 0 ? (
                    <div className="no-slot">
                        No slots available. Please add some slots.
                    </div>
                ) : (
                    <table className="slot-table">
                        <thead>
                            <tr>
                                <th>Start Time</th>
                                <th>Capacity</th>
                                <th>Price</th>
                                <th>Status</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {slotList.map((slot) => (
                                <tr key={slot.id}>
                                    <td>{slot.startTime}</td>
                                    <td>{slot.capacity}</td>
                                    <td>
                                        {editingSlot === slot.id ? (
                                            <input
                                                type="number"
                                                value={newPrice}
                                                onChange={(e) => setNewPrice(e.target.value)}
                                                className="edit-input"
                                            />
                                        ) : (
                                            `INR ${slot.price}`
                                        )}
                                    </td>
                                    <td>
                                        {slot.timePeriod === 1 ? (
                                            <span className="active-status">Enabled</span>
                                        ) : (
                                            <span className="inactive-status">Disabled</span>
                                        )}
                                    </td>
                                    <td>
                                        <div className="action-buttons">
                                            {editingSlot === slot.id ? (
                                                <>
                                                    <button className="save-button" onClick={() => handleSaveSlot(slot.id)}>Save</button>
                                                    <button className="cancel-button" onClick={handleCancelEdit}>Cancel</button>
                                                </>
                                            ) : (
                                                <>
                                                    <button className="toggle-button" onClick={() => handleDeleteSlot(slot.id)}>

                                                        <FontAwesomeIcon icon={slot.timePeriod === 0 ? faToggleOff : faToggleOn} />
                                                    </button>

                                                </>
                                            )}
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </div>
        </div>
    );
};

export default SlotPage;
