import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Register.css';
import Header from '../components/Header';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Spinner } from 'react-bootstrap'; // Import Bootstrap Spinner

const GymRegistration = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    gymName: '',
    gymDescription: '',
    gymLocation: {
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      pinCode: '',
      country: 'India',
    },
  });

  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [errors, setErrors] = useState([]);
 
  // Fetch states when the component mounts
  useEffect(() => {
    const fetchStates = async () => {
      try {
        const response = await axios.post('https://countriesnow.space/api/v0.1/countries/states', {
          country: 'India',
        });
        setStates(response.data.data.states);
      } catch (error) {
        console.error('Error fetching states:', error);
      }
    };
    fetchStates();
  }, []);

  // Fetch cities based on the selected state
  const fetchCities = async (state) => {
    try {
      const response = await axios.post('https://countriesnow.space/api/v0.1/countries/state/cities', {
        country: 'India',
        state,
      });
      setCities(response.data.data);
    } catch (error) {
      console.error('Error fetching cities:', error);
    }
  };

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle location input changes
  const handleGymLocationChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, gymLocation: { ...formData.gymLocation, [name]: value } });

    if (name === 'state') {
      fetchCities(value);
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    const { email, password, gymName, gymDescription, gymLocation } = formData;

    if (!email || !password || !gymName || !gymDescription || !gymLocation.addressLine1 || !gymLocation.pinCode || !gymLocation.state || !gymLocation.city) {
      setModalMessage('All fields are required.');
      setShowModal(true);
      return;
    }

    const registrationData = {
      email,
      password,
      name: gymName,
      description: gymDescription,
      addressLine1: gymLocation.addressLine1,
      addressLine2: gymLocation.addressLine2,
      pinCode: gymLocation.pinCode,
      city: gymLocation.city,
      state: gymLocation.state,
      country: gymLocation.country,
    };

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/register`, registrationData);
  
      if (response.status === 200 || response.status === 201) {
        setModalMessage('Please login to your email id and verify your email.');
        setShowModal(true);
  
        // Remove duplicate verification email request
        // await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/send-verify-link`, { email });
      }
    } catch (error) {
      if (error.response && error.response.status === 409) {
        setModalMessage('This email is already registered. Please log in.');
      } else {
        setModalMessage('An error occurred while registering. Please try again.');
      }
      setShowModal(true);
    }
  
    setLoading(false);
  };

  // Handle Email Verification from URL Token
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');

    if (token) {
      verifyEmail(token);
    }
  }, [location.search]);

  const verifyEmail = async (token) => {
    setLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/verify-email`, { token });

      if (response.status === 200) {
        setModalMessage('Verification Successful! Redirecting to login...');
        setShowModal(true);

        setTimeout(() => {
          window.location.href = window.location.origin + '/login';
        }, 3000);
      }
    } catch (error) {
      setModalMessage('Verification failed. Please try again.');
      setShowModal(true);
    }
    setLoading(false);
  };
   return (
    <div className="gym-registration-container">
      <Header />
      <div className="container registration-card shadow mt-5" style={{background: "#fff"}}>
        <div className="card-body">
          <h2 className="text-center mb-4">Gym Registration</h2>

    {console.log("errors received", errors)}
          {/* Display Errors */}
    {errors && errors.length > 0 && (
      <div className="alert alert-danger">
        <ul className="mb-0">
          {errors.map((error, index) => (
            <li key={index}>{error}</li>
          ))}
        </ul>
      </div>
    )}

          <div className="form-section">
            <h4>Basic Information</h4>
            {/* Form Fields */}
            <div className="form-group mb-3">
              <label>Email</label>
              <input
                type="email"
                className="form-control"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group mb-3">
              <label>Password</label>
              <input
                type="password"
                className="form-control"
                name="password"
                value={formData.password}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group mb-3">
              <label>Gym Name</label>
              <input
                type="text"
                className="form-control"
                name="gymName"
                value={formData.gymName}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group mb-3">
              <label>Gym Description</label>
              <textarea
                className="form-control"
                name="gymDescription"
                rows="3"
                value={formData.gymDescription}
                onChange={handleInputChange}
                required
              ></textarea>
            </div>

            <h4>Gym Location</h4>
            <div className="form-group mb-3">
              <label>Address Line 1</label>
              <input
                type="text"
                className="form-control"
                name="addressLine1"
                value={formData.gymLocation.addressLine1}
                onChange={handleGymLocationChange}
                required
              />
            </div>
            <div className="form-group mb-3">
              <label>Pin Code</label>
              <input
                type="text"
                className="form-control"
                name="pinCode"
                value={formData.gymLocation.pinCode}
                onChange={handleGymLocationChange}
                required
              />
            </div>
            <div className="form-group mb-3">
              <label>State</label>
              <select
                className="form-control"
                name="state"
                value={formData.gymLocation.state}
                onChange={handleGymLocationChange}
                required
              >
                <option value="">Select State</option>
                {states.map((state) => (
                  <option key={state.name} value={state.name}>
                    {state.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group mb-3">
              <label>City</label>
              <select
                className="form-control"
                name="city"
                value={formData.gymLocation.city}
                onChange={handleGymLocationChange}
                required
              >
                <option value="">Select City</option>
                {cities.map((city) => (
                  <option key={city} value={city}>
                    {city}
                  </option>
                ))}
              </select>
            </div>
 
            

            <button type="submit" className="btn btn-primary" onClick={handleSubmit}>
              Register Gym
            </button>
          </div>
        </div>
      </div>

    

 {/* Modal for Alerts & Verification */}
 <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Body className="text-center">
          {loading ? (
            <div className="d-flex justify-content-center">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Verifying...</span>
              </Spinner>
            </div>
          ) : (
            <p>{modalMessage}</p>
          )}
          {!loading && <Button variant="primary" onClick={() => setShowModal(false)}>Close</Button>}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default GymRegistration;

