import React from 'react';
import { Link } from 'react-router-dom';
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './HomePage.css';


import banner3 from '../img/yupluck app 3.png';
import PlayStoreIcon from '../img/playstore.png';
import AppStoreIcon from '../img/appstore.png';
import PromoBanner from '../img/yupluck-promo-banner.png';
import YupluckBanner from '../components/YupluckBanner';
import Logo from '../img/logo3.png';
import GymOnboardingComponent from '../components/GymOnbooarding';

const Home = () => {
    return (
        <div className="homepage">
            {/* Top App Store Icons */}
            <div className="yupluck-header">
                {/* Left: Logo */}
                <div className="yupluck-header-left">
                    <Link to="/">
                        <img src={Logo} alt="Yupluck Logo" className="yupluck-logo" />
                    </Link>
                </div>

                {/* Center: App Download Buttons */}
                

                {/* Right: Login / Register */}
                <div className="yupluck-header-right">
                    <Link to="/login" className="header-btn login-btn">Login</Link>
                    <Link to="/register" className="header-btn register-btn">Register</Link>
                </div>
            </div>



            {/* Hero Section */}
            <YupluckBanner />

            

            {/* Benefits */}
            <section className="benefits">
                <h2>Why Choose Yupluck?</h2>
                <div className="benefit-cards">
                    <div className="card">
                        <h5>🏋️‍♂️ Easy Gym Management</h5>
                        <p>Manage your gym, slots, and bookings effortlessly with our intuitive system.</p>
                    </div>
                    <div className="card">
                        <h5>📱 Streamlined Booking</h5>
                        <p>Enable smooth booking flow, reduce no-shows and maximize attendance.</p>
                    </div>
                    <div className="card">
                        <h5>📈 Boost Revenue</h5>
                        <p>Grow your earnings by optimizing slot use and customer satisfaction.</p>
                    </div>
                </div>
            </section>


            <section className="track-rewards">
                <div className="track-left">

                    <img src={PromoBanner} />


                </div>
                <div className="track-right">
                    <h3>🎯 Track Your Progress</h3>
                    <p>Monitor your workout hours, set fitness milestones, and unlock rewards to keep yourself motivated and consistent on your journey.</p>

                    <h3>💸 Refer & Earn</h3>
                    <p>Invite friends to Yupluck and earn exciting rewards when they join, book sessions, or achieve their own fitness goals.</p>

                    <h3>🔥 Stay Motivated with Yupluck</h3>
                    <p>Get badges, confetti moments, and celebration milestones that make your fitness achievements feel like a big win. Stay inspired with a vibrant community cheering you on.</p>

                    <h3>📅 Personalized Workout History</h3>
                    <p>Review your past sessions, check your gym attendance trends, and make better plans for your week. Your fitness is now data-driven and smart.</p>

                    <h3>🚀 Built for You</h3>
                    <p>Whether you're just starting out or crushing your 100th workout, Yupluck adapts to your pace, goals, and preferences — making your fitness journey truly yours.</p>
                </div>

            </section>

            {/* Track & Rewards Section */}
            <section className="track-rewards">
                <div className="track-left">
                    <video controls autoPlay loop muted playsInline>
                        <source src="/yupluck_clean_no_audio.mp4" type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
                <div className="track-right">
                    <h3>🏋️‍♂️ Streamline Your Operations</h3>
                    <p>Automate slot booking, manage gym schedules, and reduce manual work — all from one powerful dashboard.</p>

                    <h3>📈 Maximize Your Revenue</h3>
                    <p>Fill up your slots, track attendance, and use data to optimize pricing and promotions. Yupluck helps you earn more with less effort.</p>

                    <h3>📲 Go Digital with Ease</h3>
                    <p>No tech headaches. Yupluck gives you a mobile-friendly, ready-to-use platform for digital bookings, payments, and communication.</p>

                    <h3>👥 Grow Your Gym Community</h3>
                    <p>Engage with members through social features. Get reviewed, followed, and recommended by happy clients. Your gym becomes a brand.</p>

                    <h3>🔒 Secure & Reliable</h3>
                    <p>Data security and privacy are our top priorities. Enjoy peace of mind with encrypted transactions and reliable uptime.</p>

                    <h3>🎯 Powerful Insights</h3>
                    <p>Access real-time analytics on bookings, revenue, user engagement, and more — helping you make smarter business decisions.</p>
                </div>
            </section>


            <GymOnboardingComponent />





            {/* Download App CTA */}
            <section className="download-app">
                <h2>Download the Yupluck App</h2>
                <p>Book, manage and grow your fitness journey anytime, anywhere.</p>
                <div className="app-links">
                    <a href="https://play.google.com/store/apps/details?id=com.yupluck.mepluck" target="_blank" rel="noopener noreferrer">
                        <img src={PlayStoreIcon} alt="Play Store" />
                    </a>
                    <a href="https://apps.apple.com/in/app/yupluck/id6737851845" target="_blank" rel="noopener noreferrer">
                        <img src={AppStoreIcon} alt="App Store" />
                    </a>
                </div>
            </section>

            {/* Footer */}
            <footer className="footer">
                <p>&copy; {new Date().getFullYear()} Yupluck. All rights reserved.</p>
            </footer>
        </div>
    );
};

export default Home;
