import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Customer.css';
import Header from '../components/Header';
import axios from 'axios';
import { Link } from 'react-router-dom';

const Customers = () => {
    const [bookings, setBookings] = useState([]);
    const [filteredBookings, setFilteredBookings] = useState([]);
    const [selectedDate, setSelectedDate] = useState('');
    const [selectedSlotTime, setSelectedSlotTime] = useState('all');
    const [searchTerm, setSearchTerm] = useState('');

    const getAllBooking = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/customers`, {
                headers: {
                    auth: document.cookie.replace(/(?:(?:^|.*;\s*)auth\s*=\s*([^;]*).*$)|^.*$/, "$1"),
                },
            });
            const bookings = response.data.UniqueCustomers;
            setBookings(bookings);
            setFilteredBookings(bookings);
        } catch (error) {
            console.error('Error fetching bookings:', error);
        }
    };

    useEffect(() => {

        getAllBooking();
    }, []);

    useEffect(() => {
        filterBookings();
    }, [selectedDate, selectedSlotTime, searchTerm, bookings]);

    const filterBookings = () => {
        let filtered = bookings;

        if (selectedDate) {
            filtered = filtered.filter((booking) => booking.bookingDate === selectedDate);
        }

        if (selectedSlotTime !== 'all') {
            filtered = filtered.filter((booking) => booking.slotStartTime === selectedSlotTime);
        }

        if (searchTerm) {
            filtered = filtered.filter(
                (booking) =>
                    booking.userFullName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    booking.userMobileNumber.includes(searchTerm)
            );
        }

        setFilteredBookings(filtered);
    };

    const refreshData = () => {
        getAllBooking();
    };

    const getUniqueSlotTimes = () => {
        return [...new Set(bookings.map((booking) => booking.slotStartTime))];
    };

    const getBookingTypeColor = (type) => {
        switch (type.toLowerCase()) {
            case 'daily': return '#f44336';  // Red
            case 'monthly': return '#2196f3';  // Blue
            case 'quarterly': return '#ff9800';  // Orange
            case 'halfyearly': return '#9c27b0';  // Purple
            case 'yearly': return '#4caf50';  // Green
            default: return '#9e9e9e';  // Grey for unknown types
        }
    }

    const getBookingTypeDisplay = (type) => {
        switch (type.toLowerCase()) {
            case 'daily': return '1 Day';
            case 'monthly': return '1 Month';
            case 'quarterly': return '3 Months';
            case 'halfyearly': return '6 Months';
            case 'yearly': return '12 Months';
            default: return type;  // Fallback to original type if unexpected value
        }
    };

    return (
        <>
            <Header />
            <div className="container-fluid gym-display-container">
                <div className="d-flex justify-content-between align-items-center mb-4">
                    <h2 className="text-primary">My Customer</h2>
                    <button className="btn btn-primary" onClick={refreshData} title="Refresh Booking Data">
                        Refresh
                    </button>
                </div>

                <div className="row mb-3">
                    <div className="col-md-4">
                        <label htmlFor="dateSelect" className="form-label">Select Booking Date</label>
                        <input
                            type="date"
                            className="form-control"
                            id="dateSelect"
                            value={selectedDate}
                            onChange={(e) => setSelectedDate(e.target.value)}
                        />
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="slotSelect" className="form-label">Select Slot Timing</label>
                        <select
                            className="form-select"
                            id="slotSelect"
                            value={selectedSlotTime}
                            onChange={(e) => setSelectedSlotTime(e.target.value)}
                        >
                            <option value="all">All Slots</option>
                            {getUniqueSlotTimes().map((slotTime, index) => (
                                <option key={index} value={slotTime}>{slotTime}</option>
                            ))}
                        </select>
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="search" className="form-label">Search by Name or Mobile</label>
                        <input
                            type="text"
                            className="form-control"
                            id="search"
                            placeholder="Enter name or mobile number"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </div>
                </div>

                <div className="card shadow-lg mb-4">
                    <div className="card-body">
                        {filteredBookings.length > 0 ? (
                            <div className="table-responsive">
                                <table className="table table-striped table-hover table-bordered">
                                    <thead className="table-dark">
                                        <tr>
                                            <th scope="col">Booking ID</th>
                                            <th scope="col">User Full Name</th>
                                            <th scope="col">User Mobile Number</th>
                                            <th scope="col">Booking Date</th>
                                            <th scope="col">Gym Rating</th>
                                            <th scope="col">Slot Start Time</th>
                                            <th scope="col">Subscription Price</th>
                                            <th scope="col">Booking Type</th>
                                            <th scope="col">Payment Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredBookings.map((booking, index) => (
                                            <tr key={index}>
                                                <th scope="row">{booking.bookingId}</th>
                                                <td>
                                                    <Link to={`/bookings?name=${encodeURIComponent(booking.userFullName)}`}>
                                                        {booking.userFullName}
                                                    </Link>
                                                </td>
                                                <td><Link to={`/bookings?name=${encodeURIComponent(booking.userMobileNumber)}`}>
                                                        {booking.userMobileNumber}
                                                    </Link></td>
                                                <td>{new Date(booking.bookingDate).toLocaleDateString()}</td>
                                                <td>{booking.gymRating}</td>
                                                <td>{booking.slotStartTime}</td>
                                                <td>INR {booking.subscriptionPrice}</td>
                                                <td>
                                                    <span style={{
                                                        display: 'inline-block',
                                                        padding: '6px 12px',
                                                        borderRadius: '15px',
                                                        backgroundColor: getBookingTypeColor(booking.bookingType),
                                                        color: 'white',
                                                        fontWeight: 'bold',
                                                        fontSize: '0.9rem',
                                                        textTransform: 'capitalize'
                                                    }}>
                                                        {getBookingTypeDisplay(booking.bookingType)}
                                                    </span>
                                                </td>
                                                <td>
                                                    {booking.isPaid ? (
                                                        <span className="badge bg-success">Paid</span>
                                                    ) : (
                                                        <span className="badge bg-danger">Not Paid</span>
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        ) : (
                            <p className="text-center">No bookings found for the selected filters.</p>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Customers;
