import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

const AppRedirect = () => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id"); // Get 'id' from query params

  useEffect(() => {
    if (id) {
      const deepLink = `yupluck://GymDetails/${id}`;
      const fallbackURL = "https://play.google.com/store/apps/details?id=com.yupluck.mepluck"; // Change to your app's Play Store/App Store URL

      // Try opening the app
      window.location.href = deepLink;

      // If the app is not installed, redirect to the Play Store after 2 seconds
      setTimeout(() => {
        window.location.href = fallbackURL;
      }, 2000);
    }
  }, [id]);

  return (
    <div>
      <h2>Redirecting to App...</h2>
      <p>
        If nothing happens, <a href={`yupluck://GymDetails?id=${id}`}>click here</a>.
      </p>
    </div>
  );
};

export default AppRedirect;