import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Card, Button, ProgressBar } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import Header from '../components/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDumbbell, faClock, faImage, faMapMarkerAlt, faBarcode, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import './Profile.css';

const GymDisplay = () => {
    const navigate = useNavigate();
    const [gymProgress, setGymProgress] = useState(0);

    const fetchGymData = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/fetch`, {
                headers: { 'auth': document.cookie.replace(/(?:(?:^|.*;\s*)auth\s*=\s*([^;]*).*$)|^.*$/, "$1") }
            });
            setGymProgress(response.data.gym.complete);
        } catch (error) {
            console.error('Error fetching gym data:', error);
        }
    };

    useEffect(() => {
        fetchGymData();
    }, []);

    const handleNavigate = (path) => {
        navigate(path);
    };

    const steps = [
        { icon: faDumbbell, title: 'Add Equipment', text: 'List all the equipment available in your gym to attract customers.', path: '/equipment', progress: 20 },
        { icon: faClock, title: 'Add Slots', text: 'Define time slots for gym sessions, classes, or personal training.', path: '/slots', progress: 20 },
        { icon: faBarcode, title: 'Add Bank Details', text: 'Provide bank details for payment processing.', path: '/banking', progress: 20 },
        { icon: faImage, title: 'Upload Gym Image', text: 'Upload a high-quality image of your gym to attract more clients.', path: '/gallery', progress: 20 },
        { icon: faMapMarkerAlt, title: 'Update Location', text: 'Ensure your gym is easy to find by adding your exact location.', path: '/gallery', progress: 20 },
    ];

    return (
        <div className="gym-display">
            <Header />
            <div className="hero-section">

                {/* Profile Container */}
                <div className="profile-container">
                    <div className="progress-section">
                        <h2 className="progress-header">Profile Completion: {gymProgress}%</h2>
                        <ul className="progress-steps">
                            {steps.map((step, index) => (
                                <li key={index} onClick={() => handleNavigate(step.path)} className="clickable-progress">
                                    <FontAwesomeIcon icon={step.icon} className="progress-step-icon" /> {/* Icon Fix */}
                                    {step.title} - {step.progress}%
                                </li>
                            ))}
                        </ul>
                        <ProgressBar now={gymProgress} variant="success" className="custom-progress" />
                        {gymProgress < 100 ? (
                            <div className="progress-message warning">
                                <FontAwesomeIcon icon={faCheckCircle} className="progress-icon" />
                                Your gym profile needs to be 100% complete to be ready for business.
                            </div>
                        ) : (
                            <div className="progress-message success">
                                <FontAwesomeIcon icon={faCheckCircle} className="progress-icon" />
                                Congratulations! Your gym profile is complete and ready for business.
                            </div>
                        )}
                    </div>

                    <div className="steps-grid">
                        {steps.map((step, index) => (
                            <Card key={index} className="step-card">
                                <Card.Body>
                                    <div className="icon-wrapper">
                                        <FontAwesomeIcon icon={step.icon} className="step-icon" />
                                    </div>
                                    <Card.Title>{step.title}</Card.Title>
                                    <Card.Text>{step.text}</Card.Text>
                                    <Button onClick={() => handleNavigate(step.path)} className="step-button">
                                        {step.title}
                                    </Button>
                                </Card.Body>
                            </Card>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GymDisplay;
