import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Booking.css';
import Header from '../components/Header';
import axios from 'axios';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const AdminBookingPage = () => {
    const [bookings, setBookings] = useState([]);
    const [filteredBookings, setFilteredBookings] = useState([]);
    const [selectedDate, setSelectedDate] = useState('');
    const [selectedSlotTime, setSelectedSlotTime] = useState('all');
    const [chartData, setChartData] = useState(null);
    const [viewMode, setViewMode] = useState('sales');
    const [summary, setSummary] = useState({ totalBookings: 0, totalRevenue: 0, amountTobePaid: '' });
    const [paymentAmount, setPaymentAmount] = useState('');
    const [payments, setPayments] = useState([]); // State to store the payments data
    const [amountPaid, setAmountPaid] = useState(0); // Total amount paid till date
    const [remainingAmount, setRemainingAmount] = useState(0); // Remaining amount

    // Fetch bookings
    const getAllBooking = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/booking`, {
                headers: {
                    auth: document.cookie.replace(/(?:(?:^|.*;\s*)auth\s*=\s*([^;]*).*$)|^.*$/, "$1"),
                },
            });
            const bookings = response.data.Booking;
            setBookings(bookings);
            setFilteredBookings(bookings);
            generateChartData(bookings);
            calculateSummary(bookings);
        } catch (error) {
            console.error('Error fetching bookings:', error);
        }
    };

    // Fetch payments
    const getPayments = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/banking/get_payment`, {
                headers: {
                    auth: document.cookie.replace(/(?:(?:^|.*;\s*)auth\s*=\s*([^;]*).*$)|^.*$/, "$1"),
                },
            });
            const payments = response.data.payments;
            setPayments(payments);

            // Calculate total amount paid
            const totalPaid = payments.reduce((sum, payment) => sum + payment.amount_paid, 0);
            setAmountPaid(totalPaid);

            // Calculate remaining amount
            setTimeout(() => {setRemainingAmount(summary.amountTobePaid - totalPaid)}, 1000);
        } catch (error) {
            console.error('Error fetching payments:', error);
        }
    };

    useEffect(() => {
        getAllBooking();
        getPayments(); // Fetch payments when the component mounts
    }, []);

    useEffect(() => {
        filterBookings();
    }, [selectedDate, selectedSlotTime, bookings]);

    useEffect(() => {
        // Update remaining amount whenever amountPaid or amountTobePaid changes
        setRemainingAmount(summary.amountTobePaid - amountPaid);
    }, [summary.amountTobePaid, amountPaid]);

    const filterBookings = () => {
        let filtered = bookings;

        if (selectedDate) {
            filtered = filtered.filter((booking) => booking.bookingDate === selectedDate);
        }

        if (selectedSlotTime !== 'all') {
            filtered = filtered.filter((booking) => booking.slotStartTime === selectedSlotTime);
        }

        setFilteredBookings(filtered);
    };

    const calculateSummary = (bookings) => {
        const totalRevenue = bookings.reduce((sum, booking) => {
            return booking.isPaid ? sum + booking.subscriptionPrice : sum;
        }, 0);

        const totalBookings = bookings.length;

        const slotCounts = bookings.reduce((acc, booking) => {
            acc[booking.slotStartTime] = (acc[booking.slotStartTime] || 0) + 1;
            return acc;
        }, {});

        const amountTobePaid = totalRevenue - (totalRevenue * 15) / 100;

        setSummary({ totalBookings, totalRevenue, amountTobePaid });
    };

    const generateChartData = (bookings) => {
        const bookingsByDate = bookings.reduce((acc, booking) => {
            const date = new Date(booking.bookingDate).toLocaleDateString();
            acc[date] = acc[date] || { sales: 0, count: 0 };
            acc[date].sales += booking.subscriptionPrice;
            acc[date].count += 1;
            return acc;
        }, {});

        const dates = Object.keys(bookingsByDate);
        const data = dates.map((date) => bookingsByDate[date][viewMode]);

        setChartData({
            labels: dates,
            datasets: [
                {
                    label: viewMode === 'sales' ? 'Sales (INR)' : 'Bookings Count',
                    data,
                    borderColor: 'rgba(75, 192, 192, 1)',
                    backgroundColor: 'rgba(75, 192, 192, 0.2)',
                    fill: true,
                },
            ],
        });
    };

    const markComplete = async () => {
        const response = await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}/api/banking/complete_profile`, {},
            {
                headers: {
                    auth: document.cookie.replace(/(?:(?:^|.*;\s*)auth\s*=\s*([^;]*).*$)|^.*$/, '$1'),
                },
            }
        );
    }




    const handlePayment = async () => {
        if (!paymentAmount || isNaN(paymentAmount) || paymentAmount <= 0) {
            alert('Please enter a valid amount.');
            return;
        }

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_BASE_URL}/api/banking/make_payment`,
                {
                    amount: paymentAmount,
                    cred: localStorage.getItem('auth_data'),
                },
                {
                    headers: {
                        auth: document.cookie.replace(/(?:(?:^|.*;\s*)auth\s*=\s*([^;]*).*$)|^.*$/, '$1'),
                    },
                }
            );

            if (response.status === 200) {
                alert('Payment successful');
                setPaymentAmount(''); // Reset payment amount after successful payment
                getPayments(); // Refresh payments after successful payment
            } else {
                alert('Error making payment');
            }
        } catch (error) {
            console.error('Error making payment:', error);
            alert('Error making payment');
        }
    };

    return (
        <>
            <Header />
            <div className="container-fluid gym-display-container">
                <div className="card shadow-lg mb-4">
                    <div className="card-header bg-info text-white">
                        <h2 className="text-center mb-0">Booking Management</h2>
                    </div>
                    <div className="card-body">
                        {/* Summary Widget */}
                        <div className="row text-center mb-4">
                            <div className="col-md-4 col-sm-12 mb-3 mb-md-0">
                                <h5>Total Bookings</h5>
                                <p className="badge bg-primary fs-4">{summary.totalBookings}</p>
                            </div>
                            <div className="col-md-4 col-sm-12 mb-3 mb-md-0">
                                <h5>Total Revenue</h5>
                                <p className="badge bg-success fs-4">INR {Math.floor(summary.totalRevenue)}</p>
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <h5>Amount to be paid</h5>
                                <p className="badge bg-warning fs-4">INR {Math.floor(summary.amountTobePaid) || 'N/A'}</p>
                            </div>
                        </div>

                        {/* Total Paid and Remaining Amount */}
                        <div className="row text-center mb-4">
                            <div className="col-md-6 col-sm-12 mb-3 mb-md-0">
                                <h5>Total Paid</h5>
                                <p className="badge bg-success fs-4">INR {Math.floor(amountPaid)}</p>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <h5>Remaining Amount</h5>
                                <p className="badge bg-danger fs-4">INR {Math.floor(summary.amountTobePaid - amountPaid) || 'N/A'}</p>
                            </div>
                        </div>

                        {/* Payment Input */}
                        <div className="mb-4">
                            <label htmlFor="paymentAmount" className="form-label">
                                Enter Payment Amount (INR)
                            </label>
                            <input
                                type="number"
                                id="paymentAmount"
                                className="form-control"
                                value={paymentAmount}
                                onChange={(e) => setPaymentAmount(e.target.value)}
                                min="0"
                            />
                            <button className="btn btn-primary mt-3" onClick={handlePayment}>
                                Make Payment
                            </button>
                        </div>

                        <button className="btn btn-primary mt-3" onClick={markComplete}>
                                Make Profile as complete
                        </button>

                        {/* Payments List */}
                        <div className="mb-4">
                            <h4>Payment History</h4>
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Amount Paid (INR)</th>
                                        <th>Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {payments.map((payment, index) => (
                                        <tr key={payment.id}>
                                            <td>{index + 1}</td>
                                            <td>{payment.amount_paid}</td>
                                            <td>{new Date(payment.createdAt).toLocaleDateString()}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                        {/* Chart */}
                        {chartData && <Line data={chartData} />}
                    </div>
                </div>
            </div>
        </>
    );
};

export default AdminBookingPage;
